const contents = [
    {
        heading:"MASKS | 3M N95",
        para:"This healthcare respirator is designed to help provide respiratory protection for the wearer. It meets CDC guidelines for Mycobacterium tuberculosis exposure control. As a disposable particulate respirator, it is intended to reduce wearer exposure to certain airborne particles including those generated by electrocautery, laser surgery, and other powered medical instruments. As a surgical mask, it is designed to be fluid resistant to splash and spatter of blood and other infectious materials.",
        points:[
            "Comfortable braided headbands.",
            "Adjustable nose clip provides a custom fit and secure seal.",
            "Soft nose foam pad.",
            "Small-sized health care respirator and surgical mask.",
            "Helps protect against certain airborne biological particles.",
            "Fluid resistant and disposable.",
            "NIOSH Approved: N95.",
            "FDA Cleared for use as a surgical mask"
        ]
    },
    {
        heading:"VENUS Mask",
        para:"Venus V44+ filtering facepiece respirators are of fold-flat design having C Style & V Style. It has superior micro fine-filters media technology which protects against respirable suspended particulate matter. These respirators consist of multiple layers viz pre-filter, fine filter and skin comfort layer. Unique fit adjustors which provide optimum fit & comfort. They also have dotted pattern edges for a soft feel. Latex-free knitted textile elastic which has a long life, is skin-friendly and does not deform in high temperature. Head-strap stapled outside filter media which avoids puncture in filter area and is leakproof.",
        points:[
            "User-friendly, Stylish universal design.",
            "Easy to store while not in use.",
            "Protect from respirable suspended particulate matter.",
            "Composite of multiple layers; pre-filters, fine filter and skin comfort layer.",
            "Helps protect against certain airborne biological particles.",
            "No metal exposure or loose parts (Embedded nose clip).",
            "Fluid resistant and disposable,  NIOSH Approved",
            "Filters at least>80% solid airborne particles."
        ]
    },
    {
        heading:"INDUSTRIAL GRADE THERMOMETER",
        para:"The thermometer features the high-precision infrared sensors, which ensure feature stability and reliable performance. The thermometers are designed for all ages, adults, infants and the elderly. Not only does it support the forehead or ear function, but it can also randomly obtain the surface temperature of any object and the temperature of pets. To provide reliable results and ensure excellent performance<br>The thermometer can be adjusted within the range of 3-5CM/1.18-1.97in (about) which requires no a fixed measurement distance. The thermometer features the LCD large-size liquid crystal display, high brightness with backlight, clear and soft display, thus ensuring clear reading.",
       cost:"Cost : 40 pcs box/ 1,44,000 [Per pcs/4,200]"
    },
    {
        heading:"FACE SHIELDS | PRO SHIELD",
        para:"Pro Face Shield material is non-toxic virgin PET material for which we can issue the certificate of non-toxic material. The foam used is EPS not EPE based. Our competitors are using PVC material which is harmful as PVC contains chlorine gas. Ours is super clear transparent material from which we can see through. Others its hazy sheets from see-through is not possible.We use only 350 micron (0.35mm) & others competitors use 150 micron (0.15mm).",
        benefits:"benefits",
        caution:" - For daily usage of protection only, Kindly remove the protective film before using",
        points:[
            "Complete protection of face from any sort sneezing, coughing droplets, dust and oil splashes.",
            "100% transparent, non-toxic, sterilized “U.V. passed”",
            "Better flexibility & elasticity makes it light and easy to wear",
            "Reusable by sanitizing with soap, water or sanitizer",
            "Recyclable and has clear glass clarity and Scratch-resistant.",
        ],
        cost:"Cleaner PET : Sheet~.35 mm Thickness     ||  Approx. Cost: 75 (Depending on quantity)"
    },
    {
        heading:"MEDICAL GRADE-UK MADE THERMOMETER",
        para:"This new high-tech forehead and ear thermometer FDIR- V9 is specially designed to measure human body temperature by absorbing infrared energy from the forehead and ear canal of a human just in 1 second. It can help you manage the health of your family members easily, quickly and precisely at anytime and anywhere.",
        points:[
            "Dual Mode: Ear & Forehead, Auto-switching.",
            "Fast Reading: Taking temperature just in 1 second.",
            "Easy Reading: Large LED display.",
            "High Accuracy: Built-in-Germany sensor ±0.2°C (0.4°F).",
            "Easy Operation: Compact, lightweight and ergonomic design , measurement with one button.",
            "'°C/’°F switchable",
            "Safe & Reliable: Passed CE and FDA certification. Suitable for",
            "infants, babies, toddlers & adults or professional clinical diagnosis."
        ],
        cost:"Cost : 40 pcs box/ 1,88,000 [Per pcs/6000 Plus 18% GST]"
    },
    {
        heading:"FACE SHIELDS STANDARD SHIELD",
        para:"Standard Shield material is non-toxic PET material for which we can issue the certificate of non-toxic aterial. The foam used is EPS not EPE based.Our competitors are using PVC material which is harmful as PVC contains chlorine gas. Ours is super clear transparent material from which we can see through. Others its hazy sheets from see-through is not possible. We use only 200 micron (0.20mm) & others competitors use 150 micron (0.15mm).",
        caution:" -  For daily usage of protection only, kindly remove the protective film before using.",
        points:[
            "Complete protection of face from any sort sneezing, coughing droplets, dust and oil splashes.",
            "100% transparent, non-toxic, sterilized “U.V. passed”",
            "Better flexibility & elasticity makes it light and easy to wear, reusable by sanitizing with soap, water or sanitizer.",
            "Recyclable and has clear glass clarity, scratch-resistant.",
        ],
        cost:"Cleaner PET - Sheet~.20 mm Thickness || Approx. Cost: 55 (Depending on quantity)"
    },
    {
        heading:"FACE SHIELDS RESUABLE SHIELD",
        para:"Resuable Shield material is non-toxic virgin PET material for which we can issue the certificate of non-toxic material. The foam used is EPS not EPE based. Our competitors are using PVC material which is harmful as PVC contains chlorine gas. Ours is super clear transparent material from which we can see through. Others its hazy sheets from see-through is not possible. We use only 200 micron (0.20mm) & others competitors use 150 micron (0.15mm)",
        caution:" - For daily usage of protection only, Kindly remove the protective film before using.",
        points:[
            "Complete protection of face from any sort sneezing, coughing droplets, dust and oil splashes.",
            "100% transparent, non-toxic, sterilized “U.V. passed”",
            "Better flexibility & elasticity makes it light and easy to wear, reusable by sanitizing with soap, water or sanitizer.",
            "Recyclable and has clear glass clarity, scratch-resistant.",
        ],
        cost:"Cleaner PET - Sheet~.20 mm Thickness || Approx. Cost: 80 (Depending on quantity)"
    },
    {
        heading:"GOGGLE",
        para:"Safety Goggle material is non-toxic virgin PET material for which we can issue the certificate of non-toxic material. The foam used is EPS not EPE based. Our competitors are using PVC material which is harmful as PVC contains chlorine gas. Ours is super clear transparent material from which we can see through. Others its hazy sheets from see-through is not possible. We use only 350 micron (0.35mm) & others competitors use 150 micron (0.15mm).",
        caution:" - For daily usage of protection only, Kindly remove the protective film before using.",
        points:[
            "Complete protection of face from any sort sneezing, coughing droplets, dust and oil splashes.",
            "100% transparent, non-toxic, sterilized “U.V. passed”",
            "Better flexibility & elasticity makes it light and easy to wear",
            "Reusable by sanitizing with soap, water or sanitizer",
            "Recyclable and has clear glass clarity, Scratch-resistant.",
        ],
        cost:"Cleaner PET - Sheet~.35 mm Thickness || Approx. Cost: 55 (Depending on quantity)"
    },
    {
        heading:"DISINFECTION TUNNEL",
        para:"Our Disinfection Tunnels are 100% environment friendly and developed with Automated Technology. With the outbreak of COVID-19, such Disinfectant techniques is a high demand. These Disinfectant Tunnels can be used everywhere i.e. Industrial Complexes to Malls to Markets to Hospitals to Schools/Colleges, etc. Any public/overcrowded area can make use of our Disinfectant Tunnels.",
        points:[
            "Compact Size: 7’ Length x 4’ , Width x 8’ Height.",
            "Provision of sensor to detect person entering the tunnel.",
            "Disinfection spraying system for top as well as from sides to completely sanitize the person going through the tunnel.",
            "Flameproof fire retardant electrical system is provided to avoid fire because of highly inflammable disinfectant.",
            "Storage tank for Storing of Disinfectants in Disinfectant tunnel.",
            "Provision of Automatic Sanitizer machine at the entrance of every tunnel.",
        ],
        cost:"Cost: 2,50,000 plus 18% GST"
    },
    {
        heading:"AUTOMATIC MIST BASED SANITIZER DISPENSING UNIT",
        para:"A compact contactless dispensing unit sprays alcohol based sanitizer when both hands are placed under it, its aerated mist based formula uses only 5-6 ml. of sanitizer ensuring optimum usage and releases full cone spray mist for 12 seconds in single operation. The contactless technology works on Ultrasonic sensor to ensure zero touch, high operational precision to completely disinfect both hands at once. A wall-mountable unit is Enabled with two display LEDs to indicate on/off status and dispensing in progress. Bulk sanitizer container tank makes it hassle free and economical.",
        points:[],
        cost:"Cost: 11,000 plus 18% GST"
    },
    {
        heading:"INTUBATION AEROSOL BOX",
        para:"Aerosol Box help protect medical staff like doctors and nurses when they are removing a patient's intubation tube. The clear plastic cube covers the patient's head and has two holes through which the clinician passes his or her hands to perform the procedure. The intubation aerosol box has use as a temporary shield during the intubation process, and helps reduce the spread of aerosol and droplets produced by a patient cough.",
        points:[],
        cost:"Cost : 3,000 plus 18% GST"
    },
]

const table1 = ["Coffee Table Books","Newsletters","Shape Cut Books","Game Books","Books","Catalogue","Center Pin","Loop Binded","Wiro","Paper Straw","Spiral","Metpet POSN","Posters","Leaflets","Magazines","Calendars","Diaries","Carry Bags","Bunting","Dangler","Envelopes","Stickers","Hard Case Box","Soft Case Box"]
const table1_li = ["20” x 30” Komori 4 Color Offset Machine with auto plate changer (CPC).","28” x 40” Heidelberg 2 Color Offset Machine with UV Coater.","28” x 40” 6 color Komori with LED UV and Coater.","25” x 37” Komori 4 Color Offset Machine with auto plate changer (CPC)","40” x 60” Screen Printing Machine.",]
const table1_head = ["Commercial ","Our endeavor to provide the best of commercial printing solutions is visible through our printing infrastructure. We have the following machines- "]

const table2 = ["Mono-Cartons","5&7 Ply Master Cartons","Tags","Window Patching Boxes","Metpet Cartons","Labels","Rigid Box","Hard Board Boxes","3 Ply Mono - Cartons","Gift Boxes","Canister packaging","Fancy Packs",]
const table2_li = ["E- Flute 42’’, 55 \" Machine with slitter (G, A, E Flute)","Coater and Screen Printing Machine","BOBST Folder Gluer, 2 Nos. Semi-Automatic Die Cutter with Hot Foil","Meiguang FMZ-1260 High-Speed Automatic Laminator",]
const table2_head = ["Packaging ","We have state-of-the-art Packaging Infrastructure with world-class printing capabilities. Some of the aces of our packaging infrastructure are-"]

const table3 = ["Roll Up Standees","Sun Board Vending","One Way Vision","Flanges","Airlel Hangers","Injection Molded Display","Shape Cut Standees","LED Signages","Vinyl","Acrylic Display","Display Racks","Glow Sign Lit Boards","Edge Lid Boards","Flex Boards","Thermoforming","Glass Printing","Variable Printed QR Code","Glow Sign Non - Lit Boards ",]
const table3_head = ["POS Display and","Printsmith is one of the most innovative display firms in the country. We conceptualize, design, engineer, manufacture and install point of sale (POS) display stands, showroom fixtures and racks all over India."]
const table3_li = []

const table_report = {
    table1:["Books / Coffee Table / Hard Case / Soft Case / Center Pin / Children's Books ","CTB","coffee table","CB4","CB5","annual report1","anula report", "CTB 1", "cat5", "cat3" ,"cat", "cat 1", "book 2","spirla_book_mockup_2", "CB","C book","book 2","C book 2" ,"book 1a","MS" ,"annual report1","annual report","siral_book_Mockup_1","CBT 2"],
    table2:["Calendars / Carry Bags/ Diaries / Magazines ","Tirupati wall Calander",    "Tirupati Diary",    "LIC Diary",    "Syndicatedbank wall Calender",    "Lic desk calander",    "Kazo carry bag",    "JCB wall calander",    "HMD Desk calander",    "Greem carry bag",    "Continental desk calander",    "coke wall calander",    "SKG world carry bag",    "CA Diary",    "M3",    "M2",    "SyndicatedBank Diary",    "M5",    "M4",    "LIC wall calander",    "APL carry bag",    "M1",],
    table3:["Danglers / Catalogue / Posters / Leaflets / Annual Report","leafletN5",    "PN9",    "leafletN3",    "leafletN4",    "PN8",    "PN7",    "PN5",    "PN4",    "leafletN2",    "PN3",    "dangler 4",    "PN1",    "PN2",    "dangler 3",    "dangler 2",    "PN6",    "dangler 1",    "leafletN1",    "PN10",]
}

const packaging = ["Packaging Expertise ","21","23","22","24","18","19","14","13","12","10","20","11","9","8","6","7","5","3","2","4","1","16","17","15",]

const pos = ["POS DISPLAY AND FIXTURES EXPERTISE ","15","15","10","9","14","8","7","6","5","4","3","2","1","11","13","12"]


export {pos,contents,table1,table1_li,table1_head,table2, table2_li,table2_head, table3, table3_li, table3_head, table_report,packaging}


















































